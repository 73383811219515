export const AnnouncementLangs = ['zh-cn', 'vi', 'zh-tw', 'en'] as const;

export type AdminAnnouncementContent = {
  [key in (typeof AnnouncementLangs)[number]]?: string;
} & { 'zh-cn': string };

export type Announcement = {
  id: string;
  side: 'ask' | 'bid';
  subject?: string;
  content?: string;
  subjects?: AdminAnnouncementContent;
  contents?: AdminAnnouncementContent;
  publishedAt: string;
  unpinnedAt: string | null;
  unpublishedAt: string | null;
  readAt: string | null;
};
