import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import Modal from 'src/component/Modal';
import { EditProxyProfitForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: EditProxyProfitForm) => void;
};

const EditProxyProfitModal = ({ open, onClose, onSubmit }: Props) => {
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);
  const { t } = useTranslation();
  const methods = useForm<EditProxyProfitForm>();

  return (
    <Modal open={open} handleClose={() => onClose()}>
      <Form methods={methods} onSubmit={onSubmit} className="flex flex-col gap-[20px]">
        <div className="mb-[10px] text-[32px] font-bold">
          {t('userDetail.relation.editProxyProfit')}
        </div>
        {fiat.map((v) => (
          <FormNumberInput
            key={v.id}
            name={v.id}
            decimal={v.priceDecimal}
            label={v.id.toUpperCase()}
          />
        ))}

        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={() => onClose()} type="button">
            {t('act.cancel')}
          </Button>
          <Button type="submit" appearance="primary">
            {t('act.submit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditProxyProfitModal;
