import bankEndpoint from 'src/api/bankEndpoint';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';

export const getBankList = async () => {
  try {
    dispatch(startWaiting());
    const res = await bankEndpoint.getBankList();

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const createBank = async (name: string, nationality: string) => {
  try {
    dispatch(startWaiting());
    await bankEndpoint.createBank({ name, nationality });
  } finally {
    dispatch(finishWaiting());
  }
};

export const editBank = async (id: string, name: string) => {
  try {
    dispatch(startWaiting());
    await bankEndpoint.putBank(id, { name });
  } finally {
    dispatch(finishWaiting());
  }
};
