import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import { ThemeContext } from 'src/context/ThemeContext';
import IcAdd from 'src/image/ic-add-s.svg';
import { Bank } from 'src/model/Bank';
import { openSnackbar } from 'src/redux/uiSlice';
import { getBankList } from 'src/service/bankAdminService';
import AddModal from './component/AddModal';
import EditModal from './component/EditModal';

const BankAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcEmpty } = useContext(ThemeContext).image;
  const [bankList, setBankList] = useState<Bank[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>();

  useEffect(() => {
    getBankList()
      .then((res) => setBankList(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh]);

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        <div>{t('bank.heading')}</div>
        <div>
          <Button
            appearance="text"
            className="flex flex-row items-center gap-[4px]"
            onClick={() => setOpen(true)}
          >
            <img src={IcAdd} />
            {t('bank.act.create')}
          </Button>
        </div>
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px] md:px-[40px] md:py-[25px]">
        {bankList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {bankList &&
          bankList.length > 0 &&
          bankList.map((v) => (
            <div key={v.id}>
              <div className="mt-[4px] flex items-center justify-between px-[0px] py-[15px] text-[14px] font-bold text-turquoise dark:text-aqua">
                <div className="w-1/2">{v.name}</div>
                <div className="w-1/2">{t('bank.desc.' + v.nationality)}</div>
                <Button appearance="secondary" size="small" onClick={() => setEditId(v.id)}>
                  {t('bank.act.edit')}
                </Button>
              </div>
              <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
            </div>
          ))}
      </div>
      <AddModal open={open} onClose={() => setOpen(false)} doRefresh={() => setRefresh(!refresh)} />
      <EditModal
        open={editId !== undefined}
        bank={bankList.find((v) => v.id === editId)}
        onClose={() => setEditId(undefined)}
        doRefresh={() => setRefresh(!refresh)}
      />
    </div>
  );
};

export default BankAdmin;
