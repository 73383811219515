import coinEndpoint from 'src/api/coinEndpoint';
import configEndpoint from 'src/api/configEndpoint';
import cryptoEndpoint from 'src/api/cryptoEndpoint';
import exchangeRateEndpoint from 'src/api/exchangeRateEndpoint';
import orderEndpoint from 'src/api/orderEndpoint';
import rebateEndpoint from 'src/api/rebateEndpoint';
import { PostExchangeRateShiftRequest } from 'src/model/Api';
import { AddAgentRebateForm } from 'src/model/Form';
import {
  setAgentRebate,
  setBidPriceCNY,
  setBidPriceVND,
  setConfig,
  setExchangeRateCNY,
  setExchangeRateVND,
  setParentRebatePercentage,
} from 'src/redux/settingSlice';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';
import { loadCoins } from './appService';

export const init = async () => {
  try {
    dispatch(startWaiting());
    await Promise.all([
      loadConfig(),
      loadExchangeRate('cny'),
      loadExchangeRate('vnd'),
      loadCoins(),
      loadBidPrice('cny'),
      loadBidPrice('vnd'),
      loadParentRebate(),
      loadAgentRebate(),
    ]);
  } finally {
    dispatch(finishWaiting());
  }
};

const loadConfig = async () => {
  const res = await configEndpoint.getConfigs();
  dispatch(setConfig(res.data));
};

const loadExchangeRate = async (data: string) => {
  const res = await exchangeRateEndpoint.getExchangeRate('usdt', data);
  if (data === 'cny') dispatch(setExchangeRateCNY(res.data));
  else if (data === 'vnd') dispatch(setExchangeRateVND(res.data));
};

const loadBidPrice = async (data: string) => {
  const res = await orderEndpoint.getOrderPrice('usdt', data);
  if (data === 'cny') dispatch(setBidPriceCNY(res.data.price));
  else if (data === 'vnd') dispatch(setBidPriceVND(res.data.price));
};

const loadParentRebate = async () => {
  const res = await rebateEndpoint.getParentRebate();
  dispatch(setParentRebatePercentage(res.data.percentage));
};

const loadAgentRebate = async () => {
  const res = await rebateEndpoint.getAgentRebate();
  dispatch(setAgentRebate(res.data));
};

export const getCrypto = async () => {
  try {
    dispatch(startWaiting());
    const res = await cryptoEndpoint.listCryptos();

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const changeVendor = async (vendor: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('exchange-rate', { value: { vendor } });
    await Promise.all([loadConfig(), loadBidPrice('cny'), loadBidPrice('vnd')]);
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateProxyRateShift = async (data: PostExchangeRateShiftRequest) => {
  try {
    dispatch(startWaiting());
    const res = await exchangeRateEndpoint.createExchangeRateShift(data);
    if (data.quote === 'cny') {
      dispatch(setExchangeRateCNY(res.data));
      await loadBidPrice('cny');
    } else if (data.quote === 'vnd') {
      dispatch(setExchangeRateVND(res.data));
      await loadBidPrice('vnd');
    }
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateUserRateShift = async (shift: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('ask-price-delta', { value: { 'usdt/cny': shift } });
    await loadConfig();
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateVNDUserRateShift = async (shift: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('ask-price-delta', { value: { 'usdt/vnd': shift } });
    await loadConfig();
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateCNYPriceDelta = async (delta: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('price-delta', { value: { 'usdt/cny': delta } });
    await Promise.all([loadConfig(), loadBidPrice('cny')]);
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateVNDPriceDelta = async (delta: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('price-delta', { value: { 'usdt/vnd': delta } });
    await Promise.all([loadConfig(), loadBidPrice('vnd')]);
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateCNYMinProfit = async (data: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('min-profit', { value: { 'usdt/cny': data } });
    await loadConfig();
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateVNDMinProfit = async (data: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('min-profit', { value: { 'usdt/vnd': data } });
    await loadConfig();
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateAskertLimit = async (data: string) => {
  try {
    dispatch(startWaiting());
    const limit = isNaN(Number(data)) ? 0 : Number(data);
    await configEndpoint.updateConfigs('fill', { value: { askerLimit: limit } });
    await loadConfig();
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateAmount = async (id: string, minOrderAmount: string, maxOrderAmount: string) => {
  try {
    dispatch(startWaiting());
    await coinEndpoint.updateCoin(id, {
      minOrderAmount: minOrderAmount.length === 0 ? null : Number(minOrderAmount),
      maxOrderAmount: maxOrderAmount.length === 0 ? null : Number(maxOrderAmount),
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateTotal = async (id: string, minOrderTotal: string, maxOrderTotal: string) => {
  try {
    dispatch(startWaiting());
    await coinEndpoint.updateCoin(id, {
      minOrderTotal: minOrderTotal.length === 0 ? null : Number(minOrderTotal),
      maxOrderTotal: maxOrderTotal.length === 0 ? null : Number(maxOrderTotal),
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateLifetime = async (lifetime: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('order', { value: { 'bid-lifetime': lifetime } });
    await loadConfig();
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateAutoCancelTime = async (autoCancelTime: string) => {
  try {
    dispatch(startWaiting());
    await configEndpoint.updateConfigs('order', {
      value: { 'bid-auto-cancel-time': autoCancelTime },
    });
    await loadConfig();
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateFee = async (side: 'ask' | 'bid', id: string, fee: string) => {
  try {
    dispatch(startWaiting());
    await cryptoEndpoint.updateCrypto(
      id,
      side === 'ask' ? { askWithdrawalFee: Number(fee) } : { bidWithdrawalFee: Number(fee) },
    );
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateWithdrawalAutoApproved = async (min: string, max: string) => {
  try {
    dispatch(startWaiting());
    if (min !== '')
      await configEndpoint.updateConfigs('withdrawal-auto-approved-min', { value: { usdt: min } });
    if (max !== '')
      await configEndpoint.updateConfigs('withdrawal-auto-approved-max', { value: { usdt: max } });
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateParentRebate = async (percentage: string) => {
  try {
    dispatch(startWaiting());
    const res = await rebateEndpoint.postRebate({
      type: 'parent',
      percentage: Number(percentage),
    });
    dispatch(setParentRebatePercentage(res.data.percentage));
  } finally {
    dispatch(finishWaiting());
  }
};

export const createAgentRebate = async (data: AddAgentRebateForm) => {
  try {
    dispatch(startWaiting());
    await rebateEndpoint.postRebate({
      type: 'agent',
      coin: 'usdt',
      amount: Number(data.amount),
      percentage: Number(data.percentage),
    });
    await loadAgentRebate();
  } finally {
    dispatch(finishWaiting());
  }
};

export const deleteAgentRebate = async (id: string) => {
  try {
    dispatch(startWaiting());
    await rebateEndpoint.deleteRebate(id);
    await loadAgentRebate();
  } finally {
    dispatch(finishWaiting());
  }
};
