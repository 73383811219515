import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Badge from 'src/component/Badge';
import Button from 'src/component/Button';
import DateFilter from 'src/component/DateFilter';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import { MediaQuery } from 'src/constant/Media';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { OrderQueryForm } from 'src/model/Form';
import { BidOrder } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getBidOrderById, getBidOrderList } from 'src/service/orderAdminService';
import Card from './component/Card';
import Table from './component/Table';

const OrderAdmin = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const { adminOrder: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const tradableFiat = useSelector((rootState: RootState) =>
    rootState.coin.fiat.filter((f) => f.isTradable),
  );
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { userId: string } | null;
  const [filterSorting, setFilterSorting] = useState<string>(filter.sorting ?? 'default');
  const [bidOrderList, setBidOrderList] = useState<Array<BidOrder>>();
  const [orderStatus, setOrderStatus] = useState<string | undefined>(filter.status);
  const [filterFiat, setFilterFiat] = useState<string | undefined>();
  const [orderId, setOrderId] = useState<string>(filter.id ?? '');
  const [minAmount, setMinAmount] = useState<string>(filter.minAmount ?? '');
  const [maxAmount, setMaxAmount] = useState<string>(filter.maxAmount ?? '');
  const [minSentAmount, setMinSentAmount] = useState<string>(filter.minSentAmount ?? '');
  const [maxSentAmount, setMaxSentAmount] = useState<string>(filter.maxSentAmount ?? '');
  const [minProfit, setMinProfit] = useState<string>(filter.minProfit ?? '');
  const [maxProfit, setMaxProfit] = useState<string>(filter.maxProfit ?? '');
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<OrderQueryForm>({
    defaultValues: {
      id: filter.id,
      minAmount: filter.minAmount,
      maxAmount: filter.maxAmount,
      minSentAmount: filter.minSentAmount,
      maxSentAmount: filter.maxSentAmount,
      minProfit: filter.minProfit,
      maxProfit: filter.maxProfit,
    },
  });

  useEffect(() => {
    if (orderId) {
      getBidOrderById(orderId)
        .then((res) => {
          setBidOrderList([res]);
          setCount(1);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

      return;
    }

    getBidOrderList(
      {
        status: orderStatus,
        filterFiat,
        sorting: filterSorting,
        user: state?.userId,
        minAmount,
        maxAmount,
        minSentAmount,
        maxSentAmount,
        minProfit,
        maxProfit,
      },
      { begin, end, offset, limit },
    )
      .then((res) => {
        setBidOrderList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [
    orderId,
    orderStatus,
    filterFiat,
    minAmount,
    maxAmount,
    minSentAmount,
    maxSentAmount,
    minProfit,
    maxProfit,
    filterSorting,
    begin,
    end,
    offset,
    limit,
    state,
  ]);

  const onStatusFilterChange = (value: string) => {
    setOrderStatus(value !== 'all' ? value : undefined);
    paginationProps.setPage(1);
  };

  const onFiatFilterChange = (value: string) => {
    setFilterFiat(value === 'all' ? undefined : value);
    paginationProps.setPage(1);
  };

  const onSortingFilterChange = (value: string) => {
    setFilterSorting(value);
    paginationProps.setPage(1);
  };

  const onQuery = (data: OrderQueryForm) => {
    setOrderId(data.id);
    setMinAmount(data.minAmount);
    setMaxAmount(data.maxAmount);
    setMinSentAmount(data.minSentAmount);
    setMaxSentAmount(data.maxSentAmount);
    setMinProfit(data.minProfit);
    setMaxProfit(data.maxProfit);
  };

  const onDownload = () => {
    getBidOrderList(
      {
        status: orderStatus,
        filterFiat,
        sorting: filterSorting,
        user: state?.userId,
        minAmount,
        maxAmount,
        minSentAmount,
        maxSentAmount,
        minProfit,
        maxProfit,
      },
      { begin, end, offset, limit },
      true,
    ).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('order.heading')}
      </div>
      {state && (
        <Badge
          head={t('desc.userId')}
          value={state.userId}
          onClose={() => navigate('.', { replace: true })}
        />
      )}
      <div className="mt-[30px] flex flex-row gap-[15px] px-[15px] py-0 md:gap-[30px] md:px-[30px]">
        <Select
          label={t('order.desc.status')}
          defaultValue={filter.status ?? 'all'}
          onChange={onStatusFilterChange}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          <SelectOption value={'all'}>{t('order.desc.all')}</SelectOption>
          <SelectOption value={'open'}>{t('order.desc.statusDisplay.open')}</SelectOption>
          <SelectOption value={'executed'}>{t('order.desc.statusDisplay.executed')}</SelectOption>
          <SelectOption value={'open,executed'}>
            {t('order.desc.statusDisplay.openexecuted')}
          </SelectOption>
          <SelectOption value={'canceled'}>{t('order.desc.statusDisplay.canceled')}</SelectOption>
          <SelectOption value={'completed'}>{t('order.desc.statusDisplay.completed')}</SelectOption>
        </Select>
        <Select
          label={t('order.desc.coin')}
          value={filterFiat ?? 'all'}
          defaultValue="all"
          onChange={onFiatFilterChange}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          {[
            <SelectOption key={'all'} value={'all'}>
              {t('order.desc.all')}
            </SelectOption>,
          ].concat(
            tradableFiat.map((v) => (
              <SelectOption key={v.id} value={v.id}>
                {v.id.toUpperCase()}
              </SelectOption>
            )),
          )}
        </Select>
        <DateFilter
          label={t('order.desc.date')}
          onChange={() => paginationProps.setPage(1)}
          {...dateFilterProps}
        />
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        <div className="flex flex-wrap justify-between gap-x-[10px]">
          <Form
            methods={methods}
            onSubmit={onQuery}
            className="flex flex-1 flex-wrap items-center gap-x-[40px] gap-y-[10px] pb-[15px]"
          >
            <div className="flex gap-[30px]">
              <FormSearchInput
                name="id"
                placeholder={t('order.desc.searchOrderID')}
                onQuery={onQuery}
              />
              <div className="flex w-[120px] gap-[8px]">
                <img src={IcLimit} />
                <div className="grow">
                  <Select
                    value={limit.toString()}
                    onChange={(v) => {
                      setLimit(Number(v));
                      paginationProps.setPage(1);
                    }}
                  >
                    <SelectOption value="5">{'5'}</SelectOption>
                    <SelectOption value="10">{'10'}</SelectOption>
                    <SelectOption value="50">{'50'}</SelectOption>
                    <SelectOption value="100">{'100'}</SelectOption>
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-[5px]">
              <FormSearchInput
                name="minAmount"
                placeholder={t('order.desc.searchMinAmount')}
                onQuery={onQuery}
              />
              ~
              <FormSearchInput
                name="maxAmount"
                placeholder={t('order.desc.searchMaxAmount')}
                onQuery={onQuery}
              />
            </div>
            <div className="flex items-center gap-[5px]">
              <FormSearchInput
                name="minSentAmount"
                placeholder={t('order.desc.searchMinSentAmount')}
                onQuery={onQuery}
              />
              ~
              <FormSearchInput
                name="maxSentAmount"
                placeholder={t('order.desc.searchMaxSentAmount')}
                onQuery={onQuery}
              />
            </div>
            <div className="flex items-center gap-[5px]">
              <FormSearchInput
                name="minProfit"
                placeholder={t('order.desc.searchMinProfit')}
                onQuery={onQuery}
              />
              ~
              <FormSearchInput
                name="maxProfit"
                placeholder={t('order.desc.searchMaxProfit')}
                onQuery={onQuery}
              />
            </div>
            <Button appearance="secondary" size="small" type="submit">
              {t('order.desc.search')}
            </Button>
            <Button
              className="font-bold"
              size="small"
              appearance="secondary"
              type="button"
              onClick={onDownload}
            >
              {t('order.desc.download')}
            </Button>
          </Form>
          <div className="pb-[15px]">
            <SortingSelect
              defaultValue={filter.sorting ?? 'default'}
              onChange={onSortingFilterChange}
            >
              <SelectOption value="default">{t('order.desc.sortingOption.default')}</SelectOption>
              <SelectOption value="-price">{t('order.desc.sortingOption.priceDesc')}</SelectOption>
              <SelectOption value="price">{t('order.desc.sortingOption.priceAsce')}</SelectOption>
              <SelectOption value="-sent_amount">
                {t('order.desc.sortingOption.sentAmountDesc')}
              </SelectOption>
              <SelectOption value="sent_amount">
                {t('order.desc.sortingOption.sentAmountAsce')}
              </SelectOption>
              <SelectOption value="-sent_total">
                {t('order.desc.sortingOption.sentTotalDesc')}
              </SelectOption>
              <SelectOption value="sent_total">
                {t('order.desc.sortingOption.sentTotalAsce')}
              </SelectOption>
              <SelectOption value="-filled_amount">
                {t('order.desc.sortingOption.filledAmountDesc')}
              </SelectOption>
              <SelectOption value="filled_amount">
                {t('order.desc.sortingOption.filledAmountAsce')}
              </SelectOption>
              <SelectOption value="-filled_total">
                {t('order.desc.sortingOption.filledTotalDesc')}
              </SelectOption>
              <SelectOption value="filled_total">
                {t('order.desc.sortingOption.filledTotalAsce')}
              </SelectOption>
              <SelectOption value="-profit">
                {t('order.desc.sortingOption.profitDesc')}
              </SelectOption>
              <SelectOption value="profit">{t('order.desc.sortingOption.profitAsce')}</SelectOption>
            </SortingSelect>
          </div>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {bidOrderList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {bidOrderList &&
          bidOrderList.length > 0 &&
          (isBiggerThanMd ? <Table data={bidOrderList} /> : <Card data={bidOrderList} />)}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default OrderAdmin;
