import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import FillStatusLabel from 'src/component/FillStatusLabel';
import Image from 'src/component/Image';
import { Page } from 'src/constant/Page';
import { AccountUser } from 'src/model/AccountUser';
import { FillAdmin } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { confirmFill, getFillById, rejectFill } from 'src/service/fillService';
import { bnFormat } from 'src/util/bigNumber';
import ConfirmModal from './component/ConfirmModal';
import MessageModal from './component/MessageModal';

const FillDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: fillId } = useParams<{ id: string }>();
  const [fill, setFill] = useState<FillAdmin>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<'send' | 'reject'>();
  const [receiptUrl, setReceiptUrl] = useState<string>();
  const [messageTo, setMessageTo] = useState<AccountUser>();
  const dispatch = useDispatch();
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);
  const hideButton = useMemo(
    () =>
      (accountInfo?.role !== 'manager' && accountInfo?.role !== 'assistant') ||
      !fill?.sentAt ||
      !!fill?.receivedAt,
    [accountInfo, fill],
  );

  useEffect(() => {
    if (!fillId) return;

    getFillById(fillId)
      .then((res) => setFill(res))
      .catch((e) => {
        navigate(Page.Fill, { replace: true });
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [fillId, refresh]);

  const onClick = (action: 'send' | 'reject') => () => setModalAction(action);

  const receiveFill = () => {
    if (!fillId) return;
    setModalAction(undefined);
    confirmFill(fillId)
      .then(() => {
        setRefresh(!refresh);
        dispatch(openSnackbar({ message: t('fill.desc.receivedSuccess'), severity: 'notify' }));
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const reportFill = () => {
    if (!fillId) return;
    setModalAction(undefined);
    rejectFill(fillId)
      .then(() => {
        setRefresh(!refresh);
        dispatch(openSnackbar({ message: t('fill.desc.unsendSuccess'), severity: 'notify' }));
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <div className="mx-0 mb-[30px] mt-[20px] flex flex-row justify-between text-[32px] font-bold">{`${t(
        'fill.desc.fill',
      )} ${fill?.id ?? ''}`}</div>
      {!hideButton && (
        <div className="mb-[30px] flex gap-[15px]">
          <Button className="px-[30px] py-[10px] font-bold" onClick={onClick('send')}>
            {t('fill.act.sendConfirm')}
          </Button>
          <Button className="px-[30px] py-[10px] font-bold" onClick={onClick('reject')}>
            {t('fill.act.rejectConfirm')}
          </Button>
        </div>
      )}
      <div className="mb-[30px] flex gap-[20px] bg-white px-[25px] py-[30px]">
        <div className="w-[50%]">
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              ID
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill?.id}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.status')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              <div className="flex gap-[4px]">
                {fill && <FillStatusLabel fill={fill} />}
                {fill?.askerMessagedAt && (
                  <div className="w-fit rounded-[4px] bg-crimson px-[12px] py-[4px] text-[12px] font-bold leading-[16px] text-white dark:bg-red">
                    {t('fill.desc.askerMessageSent')}
                  </div>
                )}
                {fill?.bidderMessagedAt && (
                  <div className="w-fit rounded-[4px] bg-crimson px-[12px] py-[4px] text-[12px] font-bold leading-[16px] text-white dark:bg-red">
                    {t('fill.desc.bidderMessageSent')}
                  </div>
                )}{' '}
              </div>
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.createdAt')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill?.createdAt ? format(new Date(fill.createdAt), 'yyyy/MM/dd HH:mm:ss') : ''}
            </div>
          </div>
        </div>
        <div className="w-[50%]">
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.price')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill ? `${bnFormat(fill.total)} ${fill.quote.toUpperCase()}` : ''}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.profit')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill ? `${bnFormat(fill.profit)} ${fill.base.toUpperCase()}` : ''}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.cancelAt')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill?.canceledAt ? format(new Date(fill.canceledAt), 'yyyy/MM/dd HH:mm:ss') : ''}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[30px] flex gap-[30px]">
        <div className="box-border w-[50%] bg-white px-[25px] py-[30px]">
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.seller')}
            </div>
            <div
              className={classNames(
                'w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]',
                'cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua',
              )}
              onClick={() => {
                if (fill?.asker.side === 'ask') navigate(`${Page.UserAsk}/${fill.askerId}`);
                else if (fill?.asker.side === 'bid') navigate(`${Page.UserBid}/${fill.askerId}`);
                else navigate(`${Page.UserAdmin}/${fill?.askerId}`);
              }}
            >
              {t(`fill.desc.side.${fill?.asker.side}`)}-{fill?.asker.email}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.sellingAmount')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill ? `${bnFormat(fill.askAmount)} ${fill.base.toUpperCase()}` : ''}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.sellingPrice')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill ? `${bnFormat(fill.askPrice)} ${fill.quote.toUpperCase()}` : ''}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.advertisement')}
            </div>
            <div
              className={classNames(
                'w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]',
                'cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua',
              )}
              onClick={() => navigate(`${Page.Trade}/${fill?.askId}`)}
            >
              {fill?.askId}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.receivedAt')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill?.receivedAt ? format(new Date(fill.receivedAt), 'yyyy/MM/dd HH:mm:ss') : ''}
            </div>
          </div>
        </div>
        <div className="box-border w-[50%] bg-white px-[25px] py-[30px]">
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.buyer')}
            </div>
            <div
              className={classNames(
                'w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]',
                'cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua',
              )}
              onClick={() => {
                if (fill?.bidder.side === 'ask') navigate(`${Page.UserAsk}/${fill.bidderId}`);
                else if (fill?.bidder.side === 'bid') navigate(`${Page.UserBid}/${fill.bidderId}`);
                else navigate(`${Page.UserAdmin}/${fill?.bidderId}`);
              }}
            >
              {t(`fill.desc.side.${fill?.bidder.side}`)}-{fill?.bidder.email}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.buyingAmount')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill ? `${bnFormat(fill.bidAmount)} ${fill.base.toUpperCase()}` : ''}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.buyingPrice')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill ? `${bnFormat(fill.bidPrice)} ${fill.quote.toUpperCase()}` : ''}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.order')}
            </div>
            <div
              className={classNames(
                'w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]',
                'cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua',
              )}
              onClick={() => navigate(`${Page.Order}/${fill?.bidId}`)}
            >
              {fill?.bidId}
            </div>
          </div>
          <div className="flex gap-[15px] px-0 py-[4px]">
            <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('fill.desc.sentAt')}
            </div>
            <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
              {fill?.sentAt ? format(new Date(fill.sentAt), 'yyyy/MM/dd HH:mm:ss') : ''}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[30px] flex gap-[30px]">
        <div className="flex w-[50%] flex-col gap-[30px]">
          <div className="h-fit bg-white px-[25px] py-[30px]">
            <div className="mb-[10px] font-bold">{t('bankAccount.paymentMethod')}</div>
            {fill?.bankAccount?.paymentMethodType === 'bank' &&
              fill?.bankAccount?.nationality === 'CN' && (
                <>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.paymentMethodType')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {t(
                        'bankAccount.desc.paymentType.' +
                          fill?.bankAccount.paymentMethodType +
                          fill?.bankAccount.nationality,
                      )}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.bankName')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.bankName}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.province')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.province}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.city')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.city}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.bankBranchName')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.bankBranchName}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.accountNumber')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.account}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.accountName')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.name}
                    </div>
                  </div>
                </>
              )}
            {fill?.bankAccount?.paymentMethodType === 'bank' &&
              fill?.bankAccount?.nationality === 'VN' && (
                <>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.desc.paymentMethodType')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {t(
                        'bankAccount.desc.paymentType.' +
                          fill?.bankAccount.paymentMethodType +
                          fill?.bankAccount.nationality,
                      )}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.bankName')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.bankName}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.accountNumber')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.account}
                    </div>
                  </div>
                  <div className="flex gap-[15px] px-0 py-[4px]">
                    <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                      {t('bankAccount.accountName')}
                    </div>
                    <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                      {fill?.bankAccount?.name}
                    </div>
                  </div>
                </>
              )}
            {fill?.bankAccount?.paymentMethodType === 'alipay' && (
              <>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.desc.paymentMethodType')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                  </div>
                </div>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.alipayAccountName')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {fill?.bankAccount?.name}
                  </div>
                </div>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.alipayAccount')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {fill?.bankAccount?.account}
                  </div>
                </div>
              </>
            )}
            {fill?.bankAccount?.paymentMethodType === 'vietqr' && (
              <>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.desc.paymentMethodType')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                  </div>
                </div>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.alipayAccountName')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {fill?.bankAccount?.name}
                  </div>
                </div>
              </>
            )}
            {fill?.bankAccount?.paymentMethodType === 'zalopay' && (
              <>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.desc.paymentMethodType')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                  </div>
                </div>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.desc.account')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {fill?.bankAccount?.account}
                  </div>
                </div>
              </>
            )}
            {fill?.bankAccount?.paymentMethodType === 'momo' && (
              <>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.desc.paymentMethodType')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {t('bankAccount.desc.paymentType.' + fill?.bankAccount.paymentMethodType)}
                  </div>
                </div>
                <div className="flex gap-[15px] px-0 py-[4px]">
                  <div className="w-[calc(100%/3)] text-[14px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                    {t('bankAccount.desc.account')}
                  </div>
                  <div className="w-[calc(100%/3*2)] break-all text-[14px] leading-[21px]">
                    {fill?.bankAccount?.account}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="h-fit bg-white px-[25px] py-[30px]">
            <div className="mb-[10px] font-bold">{t('fill.desc.sendMessage')}</div>
            <div className="flex gap-[20px]">
              <Button onClick={() => setMessageTo(fill?.asker)}>
                {t('fill.desc.sendMessageToAsker')}
              </Button>
              <Button onClick={() => setMessageTo(fill?.bidder)}>
                {t('fill.desc.sendMessageToBidder')}
              </Button>
            </div>
          </div>
        </div>
        <div className="box-border w-[50%] bg-white px-[25px] py-[30px]">
          <div className="mb-[10px] font-bold">{t('fill.desc.receipt')}</div>
          <img
            className="max-w-[100%] cursor-pointer"
            src={fill && fill.receipts && fill.receipts[0] ? fill.receipts[0].url : ''}
            onClick={() => {
              if (fill && fill.receipts && fill.receipts[0]) setReceiptUrl(fill.receipts[0].url);
            }}
          />
        </div>
      </div>
      {receiptUrl && (
        <Image open={true} src={receiptUrl} onClose={() => setReceiptUrl(undefined)} />
      )}
      <ConfirmModal
        open={modalAction !== undefined}
        message={
          modalAction === 'send'
            ? t('fill.desc.sendConfirmMessage')
            : t('fill.desc.rejectConfirmMessage')
        }
        onClose={() => setModalAction(undefined)}
        onSubmit={modalAction === 'send' ? receiveFill : reportFill}
      />
      <MessageModal
        open={messageTo !== undefined}
        to={messageTo}
        onClose={(doRefresh: boolean) => {
          if (doRefresh) setRefresh(!refresh);
          setMessageTo(undefined);
        }}
      />
    </div>
  );
};

export default FillDetail;
