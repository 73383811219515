import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Badge from 'src/component/Badge';
import Button from 'src/component/Button';
import DateFilter from 'src/component/DateFilter';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import { MediaQuery } from 'src/constant/Media';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { QueryForm } from 'src/model/Form';
import { UserOrder } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getTradeById, getTradeList, setPriorityOfTrade } from 'src/service/tradeAdminService';
import Card from './component/Card';
import Table from './component/Table';

const TradeAdmin = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const { adminTrade: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const tradableFiat = useSelector((rootState: RootState) =>
    rootState.coin.fiat.filter((f) => f.isTradable),
  );
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { userId: string } | null;
  const [tradeList, setTradeList] = useState<Array<UserOrder>>();
  const [tradeStatus, setTradeStatus] = useState<string | undefined>(filter.status);
  const [filterFiat, setFilterFiat] = useState<string | undefined>();
  const [tradeId, setTradeId] = useState<string>(filter.id ?? '');
  const [filterSorting, setFilterSorting] = useState<string>(filter.sorting ?? 'default');
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<QueryForm>({ defaultValues: { id: filter.id } });

  useEffect(() => {
    if (tradeId) {
      getTradeById(tradeId, true)
        .then((res) => {
          setTradeList([res]);
          setCount(1);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

      return;
    }

    getTradeList(
      tradeStatus,
      filterFiat,
      filterSorting,
      { begin, end, offset, limit },
      state?.userId,
    )
      .then((res) => {
        setTradeList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [tradeId, begin, end, offset, limit, tradeStatus, filterFiat, filterSorting, state]);

  const onStatusFilterChange = (value: string) => {
    setTradeStatus(value !== 'all' ? value : undefined);
    paginationProps.setPage(1);
  };

  const onFiatFilterChange = (value: string) => {
    setFilterFiat(value === 'all' ? undefined : value);
    paginationProps.setPage(1);
  };

  const onSortingFilterChange = (value: string) => {
    setFilterSorting(value);
    paginationProps.setPage(1);
  };

  const onQuery = (data: QueryForm) => {
    setTradeId(data.id);
  };

  const onDownload = () => {
    getTradeList(
      tradeStatus,
      filterFiat,
      filterSorting,
      { begin, end, offset, limit },
      state?.userId,
      true,
    ).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onCheck = (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setPriorityOfTrade(id, event.target.checked).then((res) => {
      setTradeList(
        (tradeList ?? []).map((v) =>
          v.id === id
            ? {
                ...v,
                priority: res.priority,
              }
            : v,
        ),
      );
    });
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('trade.heading')}
      </div>
      {state && (
        <Badge
          head={t('desc.userId')}
          value={state.userId}
          onClose={() => navigate('.', { replace: true })}
        />
      )}
      <div className="mt-[30px] flex flex-row gap-[30px] px-[15px] py-0 md:gap-[30px] md:px-[15px]">
        <Select
          label={t('trade.desc.status')}
          defaultValue={filter.status ?? 'all'}
          onChange={onStatusFilterChange}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          <SelectOption value={'all'}>{t('trade.desc.all')}</SelectOption>
          <SelectOption value={'open'}>{t('trade.desc.statusDisplay.open')}</SelectOption>
          <SelectOption value={'suspended'}>{t('trade.desc.statusDisplay.suspended')}</SelectOption>
          <SelectOption value={'deleted'}>{t('trade.desc.statusDisplay.deleted')}</SelectOption>
        </Select>
        <Select
          label={t('trade.desc.coin')}
          value={filterFiat ?? 'all'}
          defaultValue="all"
          onChange={onFiatFilterChange}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          {[
            <SelectOption key={'all'} value={'all'}>
              {t('trade.desc.all')}
            </SelectOption>,
          ].concat(
            tradableFiat.map((v) => (
              <SelectOption key={v.id} value={v.id}>
                {v.id.toUpperCase()}
              </SelectOption>
            )),
          )}
        </Select>
        <DateFilter
          label={t('trade.desc.date')}
          onChange={() => paginationProps.setPage(1)}
          {...dateFilterProps}
        />
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        <div className="relative xs:flex xs:flex-wrap xs:justify-between xs:gap-x-[25px]">
          <Form
            methods={methods}
            onSubmit={onQuery}
            className="flex flex-col flex-wrap items-center pb-[15px] xs:flex-row xs:gap-[30px]"
          >
            <FormSearchInput
              name="id"
              placeholder={t('trade.desc.searchInputHelper')}
              onQuery={onQuery}
            />
            <Button appearance="secondary" size="small" type="submit">
              {t('trade.desc.search')}
            </Button>
            <div className="flex w-[120px] items-center gap-[8px]">
              <img className="h-[24px] w-[24px]" src={IcLimit} />
              <div className="grow">
                <Select
                  value={limit.toString()}
                  onChange={(v) => {
                    setLimit(Number(v));
                    paginationProps.setPage(1);
                  }}
                >
                  <SelectOption value="5">{'5'}</SelectOption>
                  <SelectOption value="10">{'10'}</SelectOption>
                  <SelectOption value="50">{'50'}</SelectOption>
                  <SelectOption value="100">{'100'}</SelectOption>
                </Select>
              </div>
            </div>
            <Button
              className="font-bold"
              size="small"
              appearance="secondary"
              type="button"
              onClick={onDownload}
            >
              {t('trade.desc.download')}
            </Button>
          </Form>
          <div className="absolute bottom-0 right-0 flex items-center pb-[15px] xs:static">
            <SortingSelect
              defaultValue={filter.sorting ?? 'default'}
              onChange={onSortingFilterChange}
            >
              <SelectOption value="default">{t('trade.desc.sortingOption.default')}</SelectOption>
              <SelectOption value="-price">{t('trade.desc.sortingOption.priceDesc')}</SelectOption>
              <SelectOption value="price">{t('trade.desc.sortingOption.priceAsce')}</SelectOption>
              <SelectOption value="-open_amount">
                {t('trade.desc.sortingOption.amountDesc')}
              </SelectOption>
              <SelectOption value="open_amount">
                {t('trade.desc.sortingOption.amountAsce')}
              </SelectOption>
            </SortingSelect>
          </div>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {tradeList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {tradeList &&
          tradeList.length > 0 &&
          (isBiggerThanMd ? (
            <Table data={tradeList} onCheck={onCheck} />
          ) : (
            <Card data={tradeList} onCheck={onCheck} />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default TradeAdmin;
