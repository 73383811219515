import {
  GetBankResponse,
  GetPaymentMethodTypesRequest,
  GetPaymentMethodTypesResponse,
  PostBankRequest,
  PostBankResponse,
  PutBankRequest,
  PutBankResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getBankList = async () => {
  try {
    return await http.authGet<GetBankResponse>('banks');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const createBank = async (data: PostBankRequest) => {
  try {
    return await http.authPost<PostBankResponse, PostBankRequest>('banks', { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const putBank = async (id: string, data: PutBankRequest) => {
  try {
    return await http.authPut<PutBankResponse, PutBankRequest>(`banks/${id}`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getPaymentMethodTypes = async (params?: GetPaymentMethodTypesRequest) => {
  try {
    return await http.authGet<GetPaymentMethodTypesResponse>('payment-method-types', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getBankList,
  createBank,
  putBank,
  getPaymentMethodTypes,
};
