import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormDatetimePicker from 'src/component/FormDatetimePicker';
import FormInput from 'src/component/FormInput';
import FormSelect from 'src/component/FormSelect';
import FormTextArea from 'src/component/FormTextArea';
import SelectOption from 'src/component/SelectOption';
import { Page } from 'src/constant/Page';
import { Announcement, AnnouncementLangs } from 'src/model/Announcement';
import { AnnouncementForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { createAnnouncement, updateAnnouncement } from 'src/service/announcementService';

const AnnouncementEdit = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as Announcement | null;

  const methods = useForm<AnnouncementForm>({
    defaultValues: {
      'zh-cn-subject': state?.subjects?.['zh-cn'],
      'zh-cn-content': state?.contents?.['zh-cn'],
      'vi-subject': state?.subjects?.vi,
      'vi-content': state?.contents?.vi,
      'zh-tw-subject': state?.subjects?.['zh-tw'],
      'zh-tw-content': state?.contents?.['zh-tw'],
      'en-subject': state?.subjects?.en,
      'en-content': state?.contents?.en,
    },
  });

  const onSubmit = (data: AnnouncementForm) => {
    if (state === null)
      createAnnouncement(data)
        .then(() => navigate(Page.Announcement, { replace: true }))
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
    else
      updateAnnouncement(state.id, data)
        .then(() => navigate(Page.Announcement, { replace: true }))
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mb-[30px] mt-[20px] text-[32px] font-bold">
        {t('announcementEdit.heading')}
      </div>
      <Form methods={methods} onSubmit={onSubmit} className="flex flex-col gap-[30px]">
        {state === null && (
          <FormSelect name="side" label={t('announcementEdit.desc.side')}>
            <SelectOption value="ask">{t('announcementEdit.desc.sideAsk')}</SelectOption>
            <SelectOption value="bid">{t('announcementEdit.desc.sideBid')}</SelectOption>
          </FormSelect>
        )}
        {state !== null && (
          <FormSelect name="side" label={t('announcementEdit.desc.side')} defaultValue={state.side}>
            <SelectOption value={state.side}>
              {state.side === 'ask'
                ? t('announcementEdit.desc.sideAsk')
                : t('announcementEdit.desc.sideBid')}
            </SelectOption>
          </FormSelect>
        )}
        <div className="flex flex-wrap gap-x-[30px] gap-y-[20px]">
          <FormDatetimePicker
            className="max-w-[230px]"
            name="publishedAt"
            label={t('announcementEdit.desc.published')}
            required
            defaultValue={state?.publishedAt ? new Date(state.publishedAt) : undefined}
          />
          <FormDatetimePicker
            className="max-w-[230px]"
            name="unpinnedAt"
            label={t('announcementEdit.desc.unpinned')}
            defaultValue={state?.unpinnedAt ? new Date(state.unpinnedAt) : undefined}
            clearable
          />
          <FormDatetimePicker
            className="max-w-[230px]"
            name="unpublishedAt"
            label={t('announcementEdit.desc.unpublished')}
            defaultValue={state?.unpublishedAt ? new Date(state.unpublishedAt) : undefined}
            clearable
          />
        </div>
        {AnnouncementLangs.map((lang: string) => (
          <>
            <FormInput
              name={`${lang}-subject`}
              label={`${lang} ${t('announcementEdit.desc.subject')}`}
              required={lang === 'zh-cn'}
            />
            <FormTextArea
              name={`${lang}-content`}
              label={`${lang} ${t('announcementEdit.desc.conetent')}`}
              required={lang === 'zh-cn'}
            />
          </>
        ))}
        <div>
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
    </div>
  );
};

export default AnnouncementEdit;
