import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { SettingTotalForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateTotal } from 'src/service/settingService';

/**
 * 廣告限額
 */
const AdLimit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);
  const methodsCNY = useForm<SettingTotalForm>();
  const methodsVND = useForm<SettingTotalForm>();

  const [fiatCNY, setFiatCNY] = useState<number>(0);
  const [fiatVND, setFiatVND] = useState<number>(0);

  useEffect(() => {
    const cny = fiat.find((v) => v.id === 'cny');
    const vnd = fiat.find((v) => v.id === 'vnd');
    if (cny === undefined || vnd === undefined) return;
    methodsCNY.setValue('minOrderTotal', cny.minOrderTotal ?? '');
    methodsCNY.setValue('maxOrderTotal', cny.maxOrderTotal ?? '');
    methodsVND.setValue('minOrderTotal', vnd.minOrderTotal ?? '');
    methodsVND.setValue('maxOrderTotal', vnd.maxOrderTotal ?? '');

    setFiatCNY(cny.priceDecimal);
    setFiatVND(vnd.priceDecimal);
  }, [fiat]);

  const onSubmitCNY = (data: SettingTotalForm) => {
    updateTotal('cny', data.minOrderTotal, data.maxOrderTotal).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  const onSubmitVND = (data: SettingTotalForm) => {
    updateTotal('vnd', data.minOrderTotal, data.maxOrderTotal).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.postLimit')}</div>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methodsCNY} onSubmit={onSubmitCNY}>
        <div className="mb-[20px] font-bold">CNY</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.systemMin')}
            </div>
            <FormNumberInput name="minOrderTotal" decimal={fiatCNY} />
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.systemMax')}
            </div>
            <FormNumberInput name="maxOrderTotal" decimal={fiatCNY} />
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methodsVND} onSubmit={onSubmitVND}>
        <div className="mb-[20px] font-bold">VND</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.systemMin')}
            </div>
            <FormNumberInput name="minOrderTotal" decimal={fiatVND} />
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.systemMax')}
            </div>
            <FormNumberInput name="maxOrderTotal" decimal={fiatVND} />
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AdLimit;
