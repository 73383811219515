import { t } from 'i18next';
import orderEndpoint from 'src/api/orderEndpoint';
import { BidOrder, FillAdmin } from 'src/model/Order';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';
import { toCsvFile } from 'src/util/toCsv';

export const getBidOrderList = async (
  params: {
    status?: string;
    filterFiat?: string;
    sorting?: string;
    user?: string;
    minAmount?: string;
    maxAmount?: string;
    minSentAmount?: string;
    maxSentAmount?: string;
    minProfit?: string;
    maxProfit?: string;
  },
  paginationParams?: PaginationParams,
  csv = false,
) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOrders<Array<BidOrder>>(
      {
        ...paginationParams,
        status: params.status,
        quote: params.filterFiat,
        sort: params.sorting === 'default' ? undefined : params.sorting,
        side: 'bid',
        order: 'desc',
        user: params.user,
        amount:
          params.minAmount || params.maxAmount
            ? [params.minAmount, params.maxAmount].join()
            : undefined,
        sentAmount:
          params.minSentAmount || params.maxSentAmount
            ? [params.minSentAmount, params.maxSentAmount].join()
            : undefined,
        profit:
          params.minProfit || params.maxProfit
            ? [params.minProfit, params.maxProfit].join()
            : undefined,
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    dispatch(
      updateFilter({
        key: 'adminOrder',
        filter: {
          id: undefined,
          status: params.status,
          sorting: params.sorting,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
          minAmount: params.minAmount,
          maxAmount: params.maxAmount,
          minSentAmount: params.minSentAmount,
          maxSentAmount: params.maxSentAmount,
          minProfit: params.minProfit,
          maxProfit: params.maxProfit,
        },
      }),
    );

    if (csv) toCsvFile('order.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getBidOrderById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getUserOrderId<BidOrder>(id);
    if (res.data.side !== 'bid') throw t('error.common.E-404-0');

    dispatch(updateFilter({ key: 'adminOrder', filter: { id } }));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getBidOrderDetailById = async (id: string): Promise<[BidOrder, FillAdmin[]]> => {
  try {
    dispatch(startWaiting());
    const [resOrder, resFill] = await Promise.all([
      orderEndpoint.getUserOrderId<BidOrder>(id),
      orderEndpoint.getOrderFillsAdmin(id),
    ]);
    if (resOrder.data.side !== 'bid') throw t('error.common.E-404-0');

    return [resOrder.data, resFill.data];
  } finally {
    dispatch(finishWaiting());
  }
};

export const cancelOrder = async (id: string) => {
  try {
    dispatch(startWaiting());
    await orderEndpoint.patchOrderCancel(id);
  } finally {
    dispatch(finishWaiting());
  }
};
