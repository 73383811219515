import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { SettingMinProfitForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateCNYMinProfit, updateVNDMinProfit } from 'src/service/settingService';

/**
 * 最小買賣價差
 */
const MinProfit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { config } = useSelector((rootState: RootState) => rootState.setting);
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);
  const methodsCNY = useForm<SettingMinProfitForm>();
  const methodsVND = useForm<SettingMinProfitForm>();

  const [fiatCNY, setFiatCNY] = useState<number>(0);
  const [fiatVND, setFiatVND] = useState<number>(0);

  useEffect(() => {
    const cny = fiat.find((v) => v.id === 'cny');
    const vnd = fiat.find((v) => v.id === 'vnd');
    if (cny === undefined || vnd === undefined) return;

    setFiatCNY(cny.priceDecimal);
    setFiatVND(vnd.priceDecimal);
  }, [fiat]);

  useEffect(() => {
    if (config === null) return;
    methodsCNY.setValue('minProfit', config['min-profit']['usdt/cny']);
    methodsVND.setValue('minProfit', config['min-profit']['usdt/vnd']);
  }, [config]);

  const onSubmitCNY = (data: SettingMinProfitForm) => {
    updateCNYMinProfit(data.minProfit).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  const onSubmitVND = (data: SettingMinProfitForm) => {
    updateVNDMinProfit(data.minProfit).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.minProfit')}</div>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methodsCNY} onSubmit={onSubmitCNY}>
        <div className="mb-[20px] font-bold">{'USDT/CNY'}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.minProfit')}
            </div>
            <FormNumberInput name="minProfit" decimal={fiatCNY} />
          </div>
          <Button
            className="min-w-[auto] px-[30px] py-[10px]"
            type="submit"
            disabled={Object.keys(methodsCNY.formState.errors).length !== 0}
          >
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methodsVND} onSubmit={onSubmitVND}>
        <div className="mb-[20px] font-bold">{'USDT/VND'}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.minProfit')}
            </div>
            <FormNumberInput name="minProfit" decimal={fiatVND} />
          </div>
          <Button
            className="min-w-[auto] px-[30px] py-[10px]"
            type="submit"
            disabled={Object.keys(methodsVND.formState.errors).length !== 0}
          >
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default MinProfit;
