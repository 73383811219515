import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormSelect from 'src/component/FormSelect';
import Modal from 'src/component/Modal';
import SelectOption from 'src/component/SelectOption';
import { BankForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { createBank } from 'src/service/bankAdminService';

type Props = {
  open: boolean;
  onClose: () => void;
  doRefresh: () => void;
};

const AddModal = ({ open, onClose, doRefresh }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<BankForm>();
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    methods.reset();
  };

  const onSubmit = (data: BankForm) => {
    createBank(data.name, data.nationality)
      .then(() => {
        doRefresh();
        handleClose();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="mb-[30px] text-[32px] font-bold">{t('bank.desc.addHead')}</div>

        <FormSelect
          className="mb-[30px]"
          name="nationality"
          label={t('bank.desc.nationality')}
          defaultValue="CN"
        >
          <SelectOption value="CN">{t('bank.desc.CN')}</SelectOption>
          <SelectOption value="VN">{t('bank.desc.VN')}</SelectOption>
        </FormSelect>

        <FormInput name="name" label={t('bank.desc.label')} />
        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button className="font-bold" appearance="outlined" onClick={handleClose} type="button">
            {t('act.cancel')}
          </Button>
          <Button className="font-bold" appearance="primary" type="submit">
            {t('bank.act.add')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddModal;
