import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { Announcement } from 'src/model/Announcement';

type Props = {
  data: Announcement[];
  onDelete: (id: string) => void;
};

const Table = ({ data, onDelete }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (announcement: Announcement) => (
    <div key={announcement.id}>
      <div className="flex flex-row">
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/7)]',
            'font-bold text-turquoise dark:text-aqua',
          )}
        >
          <div
            className="cursor-pointer hover:underline"
            onClick={() => navigate(`${Page.Announcement}/${announcement.id}`)}
          >
            {announcement.id}
          </div>
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/7)]',
          )}
        >
          {announcement.side === 'ask'
            ? t('announcement.desc.sideAsk')
            : t('announcement.desc.sideBid')}
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/7)]',
          )}
        >
          {announcement.subjects?.['zh-cn'] ?? ''}
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/7)]',
          )}
        >
          {format(Date.parse(announcement.publishedAt), 'yyyy-MM-dd HH:mm')}
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/7)]',
          )}
        >
          {announcement.unpinnedAt
            ? format(Date.parse(announcement.unpinnedAt), 'yyyy-MM-dd HH:mm')
            : '-'}
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/7)]',
          )}
        >
          {announcement.unpublishedAt
            ? format(Date.parse(announcement.unpublishedAt), 'yyyy-MM-dd HH:mm')
            : '-'}
        </div>
        <div className="flex flex-col gap-[3px] px-[12px] py-[16px]">
          <Button
            className="font-bold"
            appearance="secondary"
            size="small"
            onClick={() => {
              navigate(`${Page.Announcement}/edit`, { state: announcement });
            }}
          >
            {t('announcement.act.update')}
          </Button>
          <Button
            className="font-bold"
            appearance="secondary"
            size="small"
            onClick={() => onDelete(announcement.id)}
          >
            {t('announcement.act.delete')}
          </Button>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[calc(100%/7)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-700">
          ID
        </div>
        <div className="box-border w-[calc(100%/7)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-700">
          {t('announcement.desc.side')}
        </div>
        <div className="box-border w-[calc(100%/7)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-700">
          {t('announcement.desc.subject')}
        </div>
        <div className="box-border w-[calc(100%/7)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-700">
          {t('announcement.desc.published')}
        </div>
        <div className="box-border w-[calc(100%/7)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-700">
          {t('announcement.desc.unpinned')}
        </div>
        <div className="box-border w-[calc(100%/7)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-700">
          {t('announcement.desc.unpublished')}
        </div>
        <div className="w-[calc(100%/7)]" />
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
