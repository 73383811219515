import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { SettingExchangeRateForm, SettingUserExchangeRateForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import {
  updateProxyRateShift,
  updateUserRateShift,
  updateVNDUserRateShift,
} from 'src/service/settingService';
import { bn, bnFormat } from 'src/util/bigNumber';

/**
 * 參考匯率
 */
const ExchangeRate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { exchangeRateVND, exchangeRateCNY, config } = useSelector(
    (rootState: RootState) => rootState.setting,
  );
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);

  const [fiatCNY, setFiatCNY] = useState<number>(0);
  const [fiatVND, setFiatVND] = useState<number>(0);

  const proxyMethods = useForm<SettingExchangeRateForm>();
  const userMethods = useForm<SettingUserExchangeRateForm>();

  const proxyVNDMethods = useForm<SettingExchangeRateForm>();
  const userVNDMethods = useForm<SettingUserExchangeRateForm>();

  useEffect(() => {
    const cny = fiat.find((v) => v.id === 'cny');
    const vnd = fiat.find((v) => v.id === 'vnd');
    if (cny === undefined || vnd === undefined) return;

    setFiatCNY(cny.priceDecimal);
    setFiatVND(vnd.priceDecimal);
  }, [fiat]);

  useEffect(() => {
    if (exchangeRateCNY === null) return;
    proxyMethods.setValue('shift', exchangeRateCNY.shiftValue);
  }, [exchangeRateCNY]);

  useEffect(() => {
    if (exchangeRateVND === null) return;
    proxyVNDMethods.setValue('shift', exchangeRateVND.shiftValue);
  }, [exchangeRateVND]);

  useEffect(() => {
    if (config === null) return;
    userMethods.setValue('shift', config['ask-price-delta']['usdt/cny']);
    userVNDMethods.setValue('shift', config['ask-price-delta']['usdt/vnd'] ?? 0);
  }, [config]);

  const onProxyRateSubmit = (data: SettingExchangeRateForm) => {
    updateProxyRateShift({
      base: 'usdt',
      quote: 'cny',
      value: data.shift,
    }).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onUserRateSubmit = (data: SettingUserExchangeRateForm) => {
    updateUserRateShift(data.shift).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  const onProxyVNDRateSubmit = (data: SettingExchangeRateForm) => {
    updateProxyRateShift({
      base: 'usdt',
      quote: 'vnd',
      value: data.shift,
    }).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onUserVNDRateSubmit = (data: SettingUserExchangeRateForm) => {
    updateVNDUserRateShift(data.shift).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.rateHead')}</div>
      <Form
        className="px-[15px] py-[30px] md:px-[30px]"
        methods={proxyMethods}
        onSubmit={onProxyRateSubmit}
      >
        <div className="mb-[20px] font-bold">{'USDT/CNY'}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.rateOriginalPrice')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRateCNY ? `${bnFormat(exchangeRateCNY.originalPrice)}` : '-'}
            </div>
          </div>
          <div className="relative w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.shift')}
            </div>
            <FormNumberInput name="shift" decimal={fiatCNY} allowMinus />
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.proxyRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRateCNY ? `${bnFormat(exchangeRateCNY.price)}` : '-'}
            </div>
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
      <Form
        className="px-[15px] pb-[30px] md:px-[30px]"
        methods={userMethods}
        onSubmit={onUserRateSubmit}
      >
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.proxyRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRateCNY ? `${bnFormat(exchangeRateCNY.price)}` : '-'}
            </div>
          </div>
          <div className="relative w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.shift')}
            </div>
            <FormNumberInput name="shift" decimal={fiatCNY} allowMinus />
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.userRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRateCNY && config
                ? `${bn(exchangeRateCNY.price)
                    .plus(config['ask-price-delta']['usdt/cny'])
                    .toFormat()}`
                : '-'}
            </div>
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>

      <Form
        className="px-[15px] py-[30px] md:px-[30px]"
        methods={proxyVNDMethods}
        onSubmit={onProxyVNDRateSubmit}
      >
        <div className="mb-[20px] font-bold">{'USDT/VND'}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.rateOriginalPrice')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRateVND ? `${bnFormat(exchangeRateVND.originalPrice)}` : '-'}
            </div>
          </div>
          <div className="relative w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.shift')}
            </div>
            <FormNumberInput name="shift" decimal={fiatVND} allowMinus />
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.proxyRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRateVND ? `${bnFormat(exchangeRateVND.price)}` : '-'}
            </div>
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
      <Form
        className="px-[15px] pb-[30px] md:px-[30px]"
        methods={userVNDMethods}
        onSubmit={onUserVNDRateSubmit}
      >
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.proxyRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRateVND ? `${bnFormat(exchangeRateVND.price)}` : '-'}
            </div>
          </div>
          <div className="relative w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.shift')}
            </div>
            <FormNumberInput name="shift" decimal={fiatVND} allowMinus />
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.userRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRateVND && config
                ? `${bn(exchangeRateVND.price)
                    .plus(config['ask-price-delta']['usdt/vnd'] ?? 0)
                    .toFormat()}`
                : '-'}
            </div>
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ExchangeRate;
