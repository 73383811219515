import classNames from 'classnames';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import EditUserModal from 'src/component/EditUserModal';
import UserStatusLabel from 'src/component/UserStatusLabel';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { AccountUser, Relation } from 'src/model/AccountUser';
import { Balance } from 'src/model/Balance';
import { EditProxyProfitForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import {
  approveUser,
  blockUser,
  editProxyProfit,
  enforceDeactivateTfa,
  getUserById,
  rejectUser,
  setUserAgentRelation,
  setUserAsAgent,
  setUserNotAgent,
  unblockUser,
} from 'src/service/userService';
import { bnFormat } from 'src/util/bigNumber';
import BlockUserModal from './component/BlockUserModal';
import DeactivateOptModal from './component/DeactivateOptModal';
import DisableAgentModal from './component/DisableAgentModal';
import EditProxyProfitModal from './component/EditProxyProfitModal';
import EnableAgentModal from './component/EnableAgentModal';
import TransferModal from './component/TransferModal';
import UnblockUserModal from './component/UnblockUserModal';
import UpdateUserModal from './component/UpdateUserModal';

const UserDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: userId } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { IcCopy } = useContext(ThemeContext).image;
  const [user, setUser] = useState<AccountUser>();
  const [relation, setUserRelation] = useState<Relation>();
  const [balance, setBalance] = useState<Balance[]>();
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [blockModalOpen, setBlockModalOpen] = useState<boolean>(false);
  const [unblockModalOpen, setUnblockModalOpen] = useState<boolean>(false);
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [transferModalOpen, setTransferModalOpen] = useState<boolean>(false);
  const [editProfitModalOpen, setEditProfitModalOpen] = useState<boolean>(false);
  const [enableAgentModalOpen, setEnableAgentModalOpen] = useState<boolean>(false);
  const [disableAgentModalOpen, setDisableAgentModalOpen] = useState<boolean>(false);
  const [deactivateOtpModalOpen, setDeactivateOtpModalOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);
  const inviteUrl = useMemo(() => {
    let origin = '';
    if (window.location.host.startsWith('dev') || window.location.host.startsWith('staging'))
      origin = window.location.origin.replace('admin', 'bitop');
    else origin = window.location.origin.replace('admin.', '');

    return `${origin}/auth/register?invitation=${user?.id}`;
  }, [user]);

  useEffect(() => {
    if (userId !== undefined)
      getUserById(userId)
        .then((res) => {
          setUser(res.user);
          setUserRelation(res.relation);
          setBalance(res.balance);
        })
        .catch((e) => {
          dispatch(openSnackbar({ message: e, severity: 'alert' }));
          navigate(Page.Home, { replace: true });
        });
  }, [userId, refresh]);

  const onApprove = () => {
    if (userId === undefined) return;
    setEditModalOpen(false);
    approveUser(userId)
      .then(() => {
        setRefresh(!refresh);
        dispatch(
          openSnackbar({ message: t('user.desc.editUserSuccessfully'), severity: 'notify' }),
        );
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onReject = () => {
    if (userId === undefined) return;
    setEditModalOpen(false);
    rejectUser(userId)
      .then(() => {
        navigate(Page.UserBid);
        dispatch(
          openSnackbar({ message: t('user.desc.editUserSuccessfully'), severity: 'notify' }),
        );
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onBlock = () => {
    if (userId === undefined) return;
    setBlockModalOpen(false);
    blockUser(userId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onUnblock = () => {
    if (userId === undefined) return;
    setUnblockModalOpen(false);
    unblockUser(userId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onTransfer = (parentId: string) => {
    if (userId === undefined) return;
    setTransferModalOpen(false);
    setUserAgentRelation(userId, parentId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onEnableAgent = () => {
    if (userId === undefined) return;
    setEnableAgentModalOpen(false);
    setUserAsAgent(userId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onDisableAgent = () => {
    if (userId === undefined) return;
    setDisableAgentModalOpen(false);
    setUserNotAgent(userId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onCopy = () => {
    dispatch(openSnackbar({ message: t('message.copied') }));
  };

  const onDeactivateTfa = () => {
    if (userId === undefined) return;
    setDeactivateOtpModalOpen(false);
    enforceDeactivateTfa(userId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onEditProxyProfit = (data: EditProxyProfitForm) => {
    if (userId === undefined) return;
    setEditProfitModalOpen(false);
    editProxyProfit(userId, data)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] text-[32px] font-bold">{t('userDetail.heading')}</div>
      {user && (
        <div className="mx-0 my-[30px] flex flex-col gap-[15px] bg-white p-[25px] font-bold">
          <div className="flex items-center justify-between">
            <div>{userId}</div>
            <UserStatusLabel user={user} onEditUser={() => setEditModalOpen(true)} />
          </div>
          <div className="flex flex-wrap gap-[10px]">
            {accountInfo?.role === 'manager' && (
              <Button appearance="secondary" size="small" onClick={() => setUpdateModalOpen(true)}>
                {t('userDetail.action.edit')}
              </Button>
            )}
            {user.side !== 'admin' && (
              <Button
                appearance="secondary"
                size="small"
                onClick={() => navigate(Page.Fill, { state: { userId: user.id } })}
              >
                {t('userDetail.action.fill')}
              </Button>
            )}
            {user.side === 'bid' && (
              <Button
                appearance="secondary"
                size="small"
                onClick={() => navigate(Page.Order, { state: { userId: user.id } })}
              >
                {t('userDetail.action.order')}
              </Button>
            )}
            {user.side === 'ask' && (
              <Button
                appearance="secondary"
                size="small"
                onClick={() => navigate(Page.Trade, { state: { userId: user.id } })}
              >
                {t('userDetail.action.post')}
              </Button>
            )}
            <Button
              appearance="secondary"
              size="small"
              onClick={() => navigate(Page.WithdrawalRecord, { state: { userId: user.id } })}
            >
              {t('userDetail.action.withdrawal')}
            </Button>
            <Button
              appearance="secondary"
              size="small"
              onClick={() => navigate(Page.DepositRecord, { state: { userId: user.id } })}
            >
              {t('userDetail.action.deposit')}
            </Button>
            <Button
              appearance="secondary"
              size="small"
              onClick={() => navigate(Page.ParentRebate, { state: { userId: user.id } })}
            >
              {t('userDetail.action.parentRebate')}
            </Button>
            {user.isAgent && (
              <Button
                appearance="secondary"
                size="small"
                onClick={() => navigate(Page.AgentRebate, { state: { userId: user.id } })}
              >
                {t('userDetail.action.agentRebate')}
              </Button>
            )}
            {user.isVerified === true && user.isApproved === false && (
              <Button appearance="secondary" size="small" onClick={() => setEditModalOpen(true)}>
                {t('userDetail.action.verify')}
              </Button>
            )}
            {user.isBlocked === false && (
              <Button appearance="secondary" size="small" onClick={() => setBlockModalOpen(true)}>
                {t('userDetail.action.block')}
              </Button>
            )}
            {user.isBlocked === true && (
              <Button appearance="secondary" size="small" onClick={() => setUnblockModalOpen(true)}>
                {t('userDetail.action.unblock')}
              </Button>
            )}
            <Button
              appearance="secondary"
              size="small"
              onClick={() => navigate(Page.Message, { state: { userId: user.id } })}
            >
              {t('userDetail.action.message')}
            </Button>
            {user.side === 'ask' && user.fillsLimit !== null && (
              <Button appearance="secondary" size="small" onClick={() => navigate(Page.AskerLimit)}>
                {t('userDetail.action.askerLimit')}
              </Button>
            )}
          </div>
        </div>
      )}
      {user && (
        <div className="mx-0 my-[30px] flex flex-col gap-[30px] md:flex-row md:flex-wrap">
          <div className="box-border bg-white p-[30px] md:w-[calc(50%-15px)]">
            <div className="mb-[20px] text-[24px] font-bold">{t('userDetail.info.head')}</div>
            <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
              <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                ID
              </div>
              <div className="w-[70%] break-all leading-[21px]">{user.id}</div>
            </div>
            <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
              <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                {t('userDetail.info.side')}
              </div>
              <div className="w-[70%] break-all leading-[21px]">
                {t(`userDetail.info.${user.side}`)}
              </div>
            </div>
            <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
              <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                Email
              </div>
              <div className="w-[70%] break-all leading-[21px]">{user.email}</div>
            </div>
            <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
              <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                {t('userDetail.info.slug')}
              </div>
              <div className="w-[70%] break-all leading-[21px]">{user.slug ?? '-'}</div>
            </div>
            <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
              <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                {t('userDetail.info.mobile')}
              </div>
              <div className="w-[70%] break-all leading-[21px]">{user.mobile ?? '-'}</div>
            </div>
            {user.side === 'bid' && (
              <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('userDetail.info.paymentMethod')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">
                  {user.paymentMethodType
                    ? t(`userDetail.info.paymentMethodDisplay.${user.paymentMethodType}`)
                    : t('userDetail.info.paymentMethodDisplay.all')}
                </div>
              </div>
            )}
            <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
              <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                {t('userDetail.info.createDate')}
              </div>
              <div className="w-[70%] break-all leading-[21px]">
                {format(new Date(user.createdAt), 'yyyy/MM/dd')}
              </div>
            </div>
            <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
              <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                {t('userDetail.info.tfa')}
              </div>
              <div className="flex w-[70%] items-center gap-[10px] break-all leading-[21px]">
                <div
                  className={classNames(
                    'w-fit rounded-[4px] px-[12px] py-[3px] text-[12px] font-bold text-white',
                    {
                      'bg-green': user.isOptActivated === true,
                      'bg-khaki': user.isOptActivated === false,
                    },
                  )}
                >
                  {user.isOptActivated ? t('userDetail.info.enable') : t('userDetail.info.disable')}
                </div>
                {user.isOptActivated === true && accountInfo?.role === 'manager' && (
                  <Button
                    size="small"
                    appearance="secondary"
                    onClick={() => setDeactivateOtpModalOpen(true)}
                  >
                    {t('userDetail.info.enforceDeactivate')}
                  </Button>
                )}
              </div>
            </div>
            {user.side === 'ask' && (
              <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('userDetail.info.inviteCode')}
                </div>
                <div className="flex w-[70%] items-center gap-[10px] break-all leading-[21px]">
                  <div>{user.id}</div>
                  <CopyToClipboard onCopy={onCopy} text={user.id}>
                    <img src={IcCopy} className="cursor-pointer" />
                  </CopyToClipboard>
                </div>
              </div>
            )}
            {user.side === 'ask' && (
              <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('userDetail.info.inviteLink')}
                </div>
                <div className="flex w-[70%] items-center gap-[10px] break-all leading-[21px]">
                  <a target="_blank" href={inviteUrl} rel="noreferrer">
                    {inviteUrl}
                  </a>
                  <CopyToClipboard onCopy={onCopy} text={inviteUrl}>
                    <img src={IcCopy} className="cursor-pointer" />
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </div>
          <div className="box-border h-fit bg-white p-[30px] md:w-[calc(50%-15px)]">
            <div className="mb-[20px] text-[24px] font-bold">{t('userDetail.property.head')}</div>
            {accountInfo?.id === userId && (
              <div className="flex flex-wrap gap-[10px]">
                <Button
                  className="font-bold"
                  appearance="secondary"
                  size="small"
                  onClick={() => navigate(`${Page.UserAdmin}/deposit`)}
                >
                  {t('userProperty.act.deposit')}
                </Button>
                <Button
                  className="font-bold"
                  appearance="secondary"
                  size="small"
                  onClick={() => navigate(`${Page.UserAdmin}/withdrawal`)}
                >
                  {t('userProperty.act.withdrawal')}
                </Button>
              </div>
            )}
            {balance &&
              balance.map((v, i) => (
                <div key={v.id}>
                  <div
                    className="box-border flex min-h-[29px] cursor-pointer items-center gap-[15px] px-0 py-[4px] text-[14px]"
                    onClick={() => navigate(`${location.pathname}/property/${v.coin}`)}
                  >
                    <div className="w-[30%] text-[20px] font-bold text-dark-500">
                      {v.coin.toUpperCase()}
                    </div>
                    <div className="flex w-[70%] flex-col gap-[15px]">
                      <div>
                        <div className="text-[14px] text-black-900">
                          {t('userDetail.property.amountTotal')}
                        </div>
                        <div className="text-[20px] font-bold text-black-900">
                          {bnFormat(v.total)}
                        </div>
                      </div>
                      <div>
                        <div className="text-[14px] text-black-900">
                          {t('userDetail.property.freeAmount')}
                        </div>
                        <div className="text-[20px] font-bold text-black-900">
                          {bnFormat(v.free)}
                        </div>
                      </div>
                    </div>
                  </div>
                  {balance.length - 1 > i && (
                    <div className="mx-0 my-[20px] h-[1px] bg-light-200 dark:bg-dark-700" />
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
      {user && user.side === 'ask' && (
        <div className="box-border bg-white p-[30px]">
          <div className="mb-[20px] text-[24px] font-bold">{t('userDetail.relation.head')}</div>
          <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('userDetail.relation.name')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">{relation?.agencyName}</div>
          </div>
          <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('userDetail.relation.agent')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              <div
                className="w-fit cursor-pointer underline"
                onClick={() => navigate(`${Page.UserAsk}/${relation?.agent?.id}`)}
              >
                {relation?.agent?.email}
              </div>
            </div>
          </div>
          <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('userDetail.relation.agentCount')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">{relation?.agencyMemberCount}</div>
          </div>
          <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('userDetail.relation.parent')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              <div
                className="w-fit cursor-pointer underline"
                onClick={() => navigate(`${Page.UserAsk}/${relation?.parent?.id}`)}
              >
                {relation?.parent?.email}
              </div>
            </div>
          </div>
          <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('userDetail.relation.ancestor')}
            </div>
            <div className="flex w-[70%] items-center gap-[10px] break-all leading-[21px]">
              {relation?.ancestorsAndSelf.map((v, i) => (
                <div className={'flex items-center gap-[10px]'} key={v.id}>
                  {i > 0 && <span>{'>'}</span>}
                  <span
                    className="w-fit cursor-pointer underline"
                    onClick={() => navigate(`${Page.UserAsk}/${v.id}`)}
                  >
                    {v.email}
                  </span>
                </div>
              ))}
            </div>
          </div>
          {relation?.agent && (
            <div className="box-border flex min-h-[29px] items-center gap-[15px] px-0 py-[4px] text-[14px]">
              <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                {t('userDetail.relation.proxyProfit')}
              </div>

              <div className="flex w-[70%] flex-wrap gap-[10px] break-all leading-[21px]">
                {user.commissionDeltas?.cny !== undefined && (
                  <div>{`CNY: ${user.commissionDeltas.cny}`}</div>
                )}
                {user.commissionDeltas?.vnd !== undefined && (
                  <div>{`VND: ${user.commissionDeltas.vnd}`}</div>
                )}
              </div>
            </div>
          )}
          <div className="mt-[10px] flex flex-wrap gap-[10px]">
            <Button size="small" onClick={() => setTransferModalOpen(true)}>
              {t('userDetail.relation.transfer')}
            </Button>
            {relation?.isAgent === false && relation.parent === null && (
              <Button size="small" onClick={() => setEnableAgentModalOpen(true)}>
                {t('userDetail.relation.setAgent')}
              </Button>
            )}
            {relation?.isAgent && (
              <Button size="small" onClick={() => setDisableAgentModalOpen(true)}>
                {t('userDetail.relation.cancel')}
              </Button>
            )}
            {relation?.agent && (
              <Button size="small" onClick={() => setEditProfitModalOpen(true)}>
                {t('userDetail.relation.editProxyProfit')}
              </Button>
            )}
          </div>
          {relation && (
            <>
              <div className="mt-[10px] flex gap-[10px] border-0 border-b-[1px] border-solid border-b-light-200 px-0 py-[10px] text-[14px] font-bold text-grey-700 dark:border-b-dark-700 dark:text-grey-300/80">
                <div className="w-[calc(100%/7*2)] break-all text-[14px]">
                  {t('userDetail.relation.kid')}
                </div>
                <div className="w-[calc(100%/7*2)] break-all text-[14px]">
                  {t('userDetail.relation.userBalance')}
                </div>
                <div className="w-[calc(100%/7*2)] break-all text-[14px]">
                  {t('userDetail.relation.teamBalance')}
                </div>
                <div className="w-[calc(100%/7)] break-all text-[14px]">
                  {t('userDetail.relation.kidCount')}
                </div>
              </div>
              {relation.kids.map((v) => (
                <div
                  key={v.id}
                  className="flex gap-[10px] border-0 border-b-[1px] border-solid border-b-light-200 px-0 py-[10px] dark:border-b-dark-700"
                >
                  <div className="w-[calc(100%/7*2)] break-all text-[14px]">
                    <div
                      className="w-fit cursor-pointer underline"
                      onClick={() => navigate(`${Page.UserAsk}/${v.id}`)}
                    >
                      {v.email}
                    </div>
                  </div>
                  <div className="w-[calc(100%/7*2)] break-all text-[14px]">
                    {v.balances.length > 0 ? bnFormat(v.balances[0].total) : '0'}
                  </div>
                  <div className="w-[calc(100%/7*2)] break-all text-[14px]">
                    {relation.agencySummedBalances && relation.agencySummedBalances.length > 0
                      ? bnFormat(relation.agencySummedBalances[0].total)
                      : ''}
                  </div>
                  <div className="w-[calc(100%/7)] break-all text-[14px]">{v.kidsCount}</div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
      <EditUserModal
        open={editModalOpen}
        user={user}
        onClose={() => setEditModalOpen(false)}
        onApprove={onApprove}
        onReject={onReject}
      />
      <BlockUserModal
        open={blockModalOpen}
        onClose={() => setBlockModalOpen(false)}
        onConfirm={onBlock}
      />
      <UnblockUserModal
        open={unblockModalOpen}
        onClose={() => setUnblockModalOpen(false)}
        onConfirm={onUnblock}
      />
      {user && (
        <UpdateUserModal
          data={user}
          open={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
          onRefreshUser={() => setRefresh(!refresh)}
        />
      )}
      <TransferModal
        open={transferModalOpen}
        onClose={() => setTransferModalOpen(false)}
        onTransfer={onTransfer}
      />
      <EnableAgentModal
        open={enableAgentModalOpen}
        onClose={() => setEnableAgentModalOpen(false)}
        onConfirm={onEnableAgent}
      />
      <DisableAgentModal
        open={disableAgentModalOpen}
        onClose={() => setDisableAgentModalOpen(false)}
        onConfirm={onDisableAgent}
      />
      <DeactivateOptModal
        open={deactivateOtpModalOpen}
        onClose={() => setDeactivateOtpModalOpen(false)}
        onConfirm={onDeactivateTfa}
      />
      <EditProxyProfitModal
        open={editProfitModalOpen}
        onClose={() => setEditProfitModalOpen(false)}
        onSubmit={onEditProxyProfit}
      />
    </div>
  );
};

export default UserDetail;
