import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Pagination from 'src/component/Pagination';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import { usePagination } from 'src/hook/usePagination';
import IcAdd from 'src/image/ic-add-s.svg';
import { Announcement as AnnouncementData } from 'src/model/Announcement';
import { openSnackbar } from 'src/redux/uiSlice';
import { deleteAnnouncementById, getAdminAnnouncementList } from 'src/service/announcementService';
import Card from './component/Card';
import DeleteModal from './component/DeleteModal';
import Table from './component/Table';

const AnnouncementAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { IcEmpty } = useContext(ThemeContext).image;
  const navigate = useNavigate();
  const [announcementList, setAnnouncementList] = useState<AnnouncementData[]>([]);
  const [deleteId, setDeleteId] = useState<string>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const { limit, offset, setCount, paginationProps } = usePagination();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);

  useEffect(() => {
    getAdminAnnouncementList({ offset, limit })
      .then((res) => {
        setAnnouncementList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [offset, limit, refresh]);

  const onDelete = (id: string | undefined) => () => {
    if (id === undefined) return;
    setDeleteId(undefined);
    deleteAnnouncementById(id)
      .then(() => {
        navigate(Page.Announcement);
        setRefresh(!refresh);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        <div>{t('announcement.heading')}</div>
        <div>
          <Button
            appearance="text"
            className="flex flex-row items-center gap-[4px]"
            onClick={() => navigate(`${Page.Announcement}/edit`)}
          >
            <img src={IcAdd} />
            {t('announcement.act.create')}
          </Button>
        </div>
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        {announcementList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {announcementList &&
          announcementList.length > 0 &&
          (isBiggerThanMd ? (
            <Table data={announcementList} onDelete={(id: string) => setDeleteId(id)} />
          ) : (
            <Card data={announcementList} onDelete={(id: string) => setDeleteId(id)} />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <DeleteModal
        open={deleteId !== undefined}
        onClose={() => setDeleteId(undefined)}
        onConfirm={onDelete(deleteId)}
      />
    </div>
  );
};

export default AnnouncementAdmin;
